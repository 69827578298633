import React, { SyntheticEvent, useContext } from 'react'
import Button from 'Components/Button'
import Price from 'Components/Price/Price'
import ProductQuantityInput from 'Components/ProductQuantityInput'

import { SVGWithoutGluten, SVGWithoutLactose, SVGWithoutSugar, SVGNew, SVGBasketIconBlue } from 'Components/SVGCollection'

import cn from 'classnames'
import cg from 'Scss/app.scss'
import cl from './ProductCard.scss'
import btn from 'Components/Button/Button.scss'
import { ViewType } from 'Components/ProductQuantityInput/ProductQuantityInput'
import CartHelper from 'Utils/helpers/cartHelper'
import { connect, DispatchProp } from 'react-redux'
import { cartRequestProductUpdate } from 'Utils/redux/actions'
import { getSaleInfo, getUnitPrice, isProductNew, productIngredientInfo, isDiscounted, getImageForSizeNullable, getProductFullURL, StatusMessages, getCurrentPrice } from 'Utils/helpers/productAttributeHelper'
import { ProductListData, CartFragment, ProductStatus } from 'Utils/api/gql/types'
import ProductLink from 'Components/ProductLink'

import { numberFormatter, getRelativeTime } from 'Utils/helpers/formatters'
import { getCategoryStringForPrefixbox, prefixboxClick } from 'Utils/helpers/prefixboxHelper'
import { showDeliveryTypeModal } from 'Utils/helpers/modalHelper'
import memoizeOne from 'memoize-one';
import { SALE_BADGE_MINIMUM_AMOUNT } from 'Utils/helpers/constants';
import gtmClientInstance, { ProductCardGA4Type } from 'Utils/gtmClient';
import Logger from 'Utils/Logging'
import product from 'Utils/api/gql/queries/product';
import { WasDisplayedIndexContext } from 'Components/ScrollableList/ScrollableList';

type StateProps = {
  cart: CartFragment | null,
  cartStatus: Redux.ICartStatus
  productAttributes: Array<Webshop.IProductAttribute>
  deliveryAddress?: Redux.IDeliveryAddress
  productCategories: Webshop.ICategory[]
  orderedProductIds: Redux.OrderedProductEntry[] | null
}

type OwnProps = {
  product: ProductListData
  view?: 'grid' | 'list' | 'promo'
  className?: string
  loading?: boolean
  analytics: Redux.PrefixboxAnalyticsInfo
  emphasized?: boolean
  ga4?: ProductCardGA4Type,
} & Partial<DefaultProps>

type PropType = StateProps & OwnProps & DispatchProp

type DefaultProps = Readonly<typeof defaultProps>

const defaultProps = {
  view: 'grid' as 'grid' | 'list' | 'promo'
}

const MOCK_PRODUCT_NAME = "LOADING";
class ProductCard extends React.PureComponent<PropType> {
  static defaultProps = defaultProps

  componentDidMount() {
    if (this.props.product && this.props.product.name !== MOCK_PRODUCT_NAME && this.props.ga4) {
      gtmClientInstance.sendViewItemList(this.props.product, this.props.ga4);
    }
  }

  componentDidUpdate(prevProps: PropType) {
    if (this.props.product && this.props.product.name !== MOCK_PRODUCT_NAME && this.props.ga4 && prevProps.ga4 !== this.props.ga4) {
      gtmClientInstance.sendViewItemList(this.props.product, this.props.ga4);
    }
  }

  handleQuantityChange = async (newQuantity: number, quantityType: Redux.QtyType) => {
    this.props.dispatch(cartRequestProductUpdate({
      product: this.props.product,
      newQuantity,
      quantityType,
      analytics: this.props.analytics
    }))
  }

  addToCartFactory = (type: Redux.QtyType) => async (e?: SyntheticEvent) => {
    e && e.preventDefault()

    const doAddToCart = () => this.props.dispatch(cartRequestProductUpdate({ product: this.props.product, quantityType: type, analytics: this.props.analytics }));

    if (!this.props.deliveryAddress) {
      showDeliveryTypeModal(true, () => {
        doAddToCart()
      })
      return
    }

    doAddToCart()
  }

  _getPreviouslyOrderedInfo = memoizeOne((id: string) => {
    return (this.props.orderedProductIds || []).find(entry => entry[0] === id)
  })

  getPreviouslyOrderedInfo = () => {
    return this._getPreviouslyOrderedInfo(this.props.product.id)
  }

  handleProductClick = () => {
    const { product, analytics, productCategories } = this.props

    if (analytics.query) {
      const inputParams: PrefixboxClickParams = {
        productId: product.id,
        category: getCategoryStringForPrefixbox(product, productCategories),
        title: product.name,
        url: getProductFullURL(product),
        pattern: analytics.query,
        position: analytics.position + 1
      }
      Logger.debug('[PB] prefixboxClick', inputParams)

      prefixboxClick(inputParams)
    }
    gtmClientInstance.sendProductClick(product, analytics.position + 1)
  }

  render () {
    const {
      className,
      product,
      view,
      dispatch,
      loading,
      productAttributes,
      deliveryAddress,
      cartStatus,
      cart,
      productCategories,
      orderedProductIds,
      emphasized,
      ...otherProps
    } = this.props

    const freeInfo = productIngredientInfo(product)
    const cartItem = CartHelper.getInCartItem(product.id, cart)
    const isSale = getSaleInfo(product)
    const isNew = isProductNew(product)
    const currentPrice = getCurrentPrice(product)

    const classNames = `${cl.productCard} ${cl.productCard} ${emphasized ? cl.emphasized : 'xxx'} ${view === 'grid' ? cl.productCardGrid : ''} ${view === 'promo' ? cl.productCardPromo : ''} ${view === 'list' ? cl.productCardList : ''} ${className || ''} ${cartItem ? cl.productCardInCart : ''}`.trim()
    const layoutClassName = `${['promo', 'list'].includes(view || '') ? `${cg.gridX} ${cl.gridX}` :`${cg.gridY} ${cl.gridY}`}`

    const isFetching = CartHelper.isProductFetching(product, cartStatus)
    const isProductUnavailable = product.status !== ProductStatus.AVAILABLE
    const isAddToCartDisabled = isFetching || isProductUnavailable

    const image = getImageForSizeNullable(product, 0, 'list')
    const previouslyOrdered = this.getPreviouslyOrderedInfo()

    return (
      <span className={`${classNames}`} {...otherProps}>
        <span className={`${layoutClassName}`}>


          <span className={`${cg.cell} ${view === 'promo' ? `${cl.gridPadding}` : ""}  ${['promo', 'list'].includes(view || '') ? cg.shrink : '' }`}>
            <ProductLink product={product} onClick={this.handleProductClick}>
              <span className={cn(cl.productCardImgContainer)}>

                {image && <img src={image} />}

                {freeInfo && (
                  <span className={cn(cl.productCardFreeWrapper)}>
                    {freeInfo.gluten && (
                      <span>
                        <span className={cn(cg.showForSr)}>gluténmentes</span>

                        <SVGWithoutGluten width="24" height="24" aria-hidden="true" title="Gluténmentes" />
                      </span>
                    )}

                    {freeInfo.lactose && (
                      <span>
                        <span className={cn(cg.showForSr)}>laktózmentes</span>

                        <SVGWithoutLactose width="24" height="24" aria-hidden="true"  title="Laktózmentes" />
                      </span>
                    )}

                    {freeInfo.sugar && (
                      <span>
                        <span className={cn(cg.showForSr)}>cukormentes</span>

                        <SVGWithoutSugar width="24" height="24" aria-hidden="true"  title="Cukormentes" />
                      </span>
                    )}
                  </span>
                )}
              </span>
            </ProductLink>
          </span>

          <span className={`${view === 'promo' ? `${cg.cell} ${cg.auto} ${cl.gridPadding}` : ''}`}>
            <span className={`${view === 'promo' ? `${cg.gridY} ${cl.gridY}` : ''}`}>

              <ProductLink product={product} onClick={this.handleProductClick}>
                <span className={cn(cg.cell, cg.auto, cl.productCardDataWrapper)}>
                  <span className={cn(cl.productCardDataName)}>

                      {product.name}

                  </span>
                </span>

                {!!currentPrice && <span className={cn(cg.cell, cg.shrink, cl.price)}>
                  <Price
                    product={product}
                  >
                    {numberFormatter(getUnitPrice(product))} Ft / {product.price_unit}
                  </Price>
                </span>}
              </ProductLink>

              <span className={`${view === 'list' ? '' : `${cg.cell} ${cg.shrink}`} ${cl.productCardCartWrapper}`}>
                {cartItem
                  ? (
                    <div style={view === 'grid' ? {marginTop: '15px'} : {marginTop: '5px'}}>
                      <ProductQuantityInput
                        view={view === 'promo' ? ViewType.Promo : ViewType.Product }
                        cartItem={cartItem}
                        onChange={this.handleQuantityChange}
                      />
                    </div>
                  )
                  : !!currentPrice && (
                    <>
                      <Button
                        style={{marginTop: '15px'}}
                        label="Kosárba"
                        className={cn(cl.button)}
                        renderIcon={{
                          ariaLabel: 'Kosárba',
                          Icon: <SVGBasketIconBlue width="24" height="24" aria-hidden="true" />,
                        }}
                        disabled={isAddToCartDisabled}
                        onClick={this.addToCartFactory('default')}
                      />
                      <div className={`${cl.productQuantityInfo}`}>
                        {product.alternative_quantity_name && !isProductUnavailable && `1 ${product.alternative_quantity_name} = ${product.alternative_quantity_exchange_rate} ${product.default_quantity_name}`}
                      </div>

                      {isProductUnavailable && <p className={cl.unavailableInfo}>{StatusMessages[product.status]}</p>}
                      {product.alternative_quantity_name && !isProductUnavailable &&
                        <Button
                          label={`+1 ${product.alternative_quantity_name} a kosárba`}
                          className={cn(btn.button, btn.blank, cl.cardboardLink)}
                          onClick={this.addToCartFactory('alternative')}
                        />
                      }
                    </>
                  )
                }
                {!currentPrice && isProductUnavailable && <p className={cl.unavailableInfo}>{StatusMessages[product.status]}</p>}
              </span>
            </span>
          </span>
        </span>

        <span className={cn(cl.actionWrapper)}>

          {emphasized && !this.props.loading && (
            <span className={cn(cl.emphasizedLabel)}>
              Speciális
              ajánlat!
            </span>
          )}

          {previouslyOrdered && !this.props.loading && <span className={cn(cl.ordered)}>
            <strong>Korábban rendelt</strong>
            <span>{getRelativeTime(previouslyOrdered[3])} {previouslyOrdered[1]} {previouslyOrdered[2]}</span>
          </span>}

          {isDiscounted(product) && !this.props.loading && isSale.amount >= SALE_BADGE_MINIMUM_AMOUNT && (
            <span className={cn(cl.discount)}>
              {isSale.info ? (
                <strong>{isSale.info}-ig</strong>
              ) : null}
            </span>
          )}
          {isNew && !this.props.loading && (
            <span className={cn(cl.new)}>
              <span className={cg.showForSr}>Új</span>
              <span aria-hidden="true">
                <SVGNew width={40} height={40} />
              </span>
            </span>
          )}

          {/* TODO: is netPrice === egyedi ár? */}
          {/* {false && product.isUniquePrice && (
            <span className={cl.productCardCustomPrice}>
              Egyedi
              <br />
              <span>ár</span>
            </span>
          )} */}
        </span>

        {loading && <div className={`${cl.loadingLayer} ${cl.open}`}>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div> }
      </span>
    )
  }
}

function mapToStateProps({ cart, cartStatus, scheduledData, deliveryAddress, productCategories, orderedProductIds }: Redux.IReduxState): StateProps  {
  return {
    cart,
    cartStatus,
    productAttributes: scheduledData.productAttributes,
    deliveryAddress,
    productCategories,
    orderedProductIds
 }
}

const ConnectedProductCard = connect<StateProps, {}, OwnProps, Redux.IReduxState>(mapToStateProps)(ProductCard);

const ContextedConnectedProductCard = (props: OwnProps) => {
  const displayed = useContext(WasDisplayedIndexContext);
  const { ga4, ...rest } = props;
  if (!ga4 || !ga4.position || !displayed) {
    return <ConnectedProductCard {...rest} ga4={ga4} />
  }
  return <ConnectedProductCard ga4={displayed > ga4.position ? ga4 : undefined} {...rest} />;
}

export default ContextedConnectedProductCard;
